<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('SubjectList')}}</div>
            </div>
            <template>
                <avue-crud ref="crud" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange">
                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('CORPORATION')}}:</span>
                                    <el-input v-model="corporationVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('ORDER')}}:</span>
                                    <el-input v-model="orderVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('PROCESS')}}:</span>
                                    <el-input v-model="processVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top:10px;">
                                <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="searchFrom">  {{$t('Search')}}</el-button>
                            </el-col>

                        </el-row>
                    </template>
                </avue-crud>
            </template>
        </basic-container>
    </div>
</template>
<style scoped>
    .dataicon i {
        display: none;
    }
</style>
<script>
    import { subjectList } from "@/api/Subject";
    export default {
        data() {
            return {
                corporationVal: "",
                orderVal: "",
                processVal: "",
                statusVal: "",
                tableData: [],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize: 10
                }
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        menuBtn: false,
                        menu: false,
                        addBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        header: true,
                        column: [
                            {
                                label: this.$t('CORPORATION'),
                                prop: 'corporationId',
                            },
                            {
                                label: this.$t('ORDER'),
                                prop: 'orderId',
                            },
                            {
                                label: this.$t('PROCESS'),
                                prop: 'sectionProcessId',
                            },
                            {
                                label: this.$t('DATE'),
                                prop: 'createTime',
                                type: "date",
                                editDisplay: false,
                                addDisplay: false,
                                format: "yyyy-MM-dd HH:mm:ss",
                                valueFormat: "yyyy-MM-dd HH:mm:ss"
                            }
                        ]
                    }
                }
            }
        },
        created() {
            this.getsubjectList();
        },
        methods: {
            focusDiv(obj) {
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                }

            },
            rowStyle({ row, column, rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },
            currentChange(val) {
                this.page.currentPage = val;
                this.getsubjectList();
            },
            searchFrom() {
                this.getsubjectList();
            },
            
            getsubjectList() {
                subjectList(this.corporationVal, this.orderVal, this.processVal, this.page.currentPage, this.page.pageSize).then(res => {
                    this.tableData = res.data.subjects;
                    this.page.total = res.data.totalCount;
                    this.page.pageSize = res.data.pageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },
        }
    }
</script>